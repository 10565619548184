
.list{
  /*background: lightgrey;*/
  overflow-y: scroll;
  position: relative;
}
@font-face {
  font-family: "iconfont";
  src: url("../../assets/iconfont/iconfont.ttf?t=1683289479078")
  format("truetype");
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.logo {
  width: 100%;
  height: 91px;
}

.title {
  color: grey;
  font-weight: bold;
  font-size: 25px;
}

.list-header {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
}

.list-header-left {
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
}

.list-content {
  position: relative;
  border-top: 0.3rem solid #34a2ff;
  /*width: 36.9rem;*/
  background: white;
  padding: 0.2rem;
  box-sizing: border-box;
  width: 100%;
  margin: 0.3rem 0.3rem 100px;
}

.timer {
  color: #34a2ff;
}

.time{
  margin-bottom: 0.5rem;
}

.handleIcon{
  position: absolute;
  right: 15px;
  top: 10px;
  color: green;
  font-size: 50px;
}
.response-img{
  width: 100%;
}
.response{
  margin-top: 0.5rem;
  border-top: 0.1rem dashed lightgrey;
  padding: 0.2rem;
}
.response-text{
  color: #34a2ff;
  margin-bottom: 0.5rem;
}